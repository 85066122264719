.signup-page,
.login-page {
  /*background: url("./images/frame-1668890_1920.png") no-repeat center fixed;
  background-position: 40px 70px;
  background-size: 90%;
  height: 1500px;*/
}

.signup-form,
.login-form {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px 20px;
}

.signup-form label,
.login-form label {
  margin-top: 20px;
  /*visibility: hidden;*/
  color: blue;
  font-size: 18px;
}

.signup-form .form-input,
.login-form .form-input,
.change-pwd-form .form-input,
.reset-pwd-req-form .form-input,
.signup-approval-form .form-input {
  height: 35px;
  margin-bottom: 20px;
  border-radius: 0px;
  border: 0;
  outline: 0;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0);
  border-bottom: solid 2px blue;
}

.signup-form .form-check-input {
  height: 15px;
}

.signup-form .btn,
.login-form .btn {
  margin: 15px 0px;
  width: 100%;
}

.reset-pwd-req-msg,
.signup-approval-form-msg {
  margin: 20px 10px 20px 10px;
}
