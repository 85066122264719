.react-datepicker__close-icon {
  margin-top: 0px;
}

.fitbit-participant-form {
  width: 90%;
}

.fitbit-participant-form h4 {
  margin-bottom: 5px;
  color: blue;
}

.calendar .react-datepicker-wrapper {
  margin-bottom: 3px;
}

.calendar .react-datepicker__input-container {
  width: 93%;
}

.calendar .react-datepicker-wrapper input {
  border-radius: 5px;
  padding: 4px 5px;
  border: 2px solid #fdd591;
  /* background: #fee8c3; */
}

.fitbit-participant-form .form-btn {
  /* margin-top: 5px; */
  /* margin-left: 2px; */
  position: relative;
  float: right;
  width: 25px;
  height: 25px;
  margin-right: 30px;
}

.refresh-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  outline: none;
}

.overview-icon {
  position: absolute;
  top: 0;
  left: 20;
  width: 25px;
  height: 25px;
  outline: none;
}
