.dashboard-container a {
  margin-right: 20px;
}

.tableContainer {
  width: 100%;
  margin: auto;
  overflow: auto;
  padding: 0px 5px 10px 5px;
  border: solid 1px lightgray;
}
