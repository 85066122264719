.modal-form {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px 20px;
}

.modal-form .form-item {
  margin-top: 10px;
}

.modal-form label {
  margin-top: 20px;
  color: blue;
  font-size: 18px;
}

.modal-form input,
.modal-form select,
.modal-form .form-input {
  width: 100%;
  height: 35px;
  margin-bottom: 20px;
  border-radius: 0px;
  border: 0;
  outline: 0;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0);
  border-bottom: solid 2px blue;
  overflow-x: visible;
}

.modal-form .form-check-input {
  height: 15px;
}

.modal-form .form-group {
  text-align: center;
}

.modal-form button:hover {
  background-color: lightgray;
}

.modal-form-submit-icon {
  font-size: 3em;
  color: green;
}

.modal-form-cancel-icon {
  font-size: 3em;
  color: red;
}
