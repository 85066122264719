/* Hide the original radio button*/
.radio-btn-container input[type='radio'] {
  position: absolute;
  opacity: 0;
  margin: 0;
}
.radio-btn-container {
  position: relative;
  display: block;
  width: 93%;
  /* margin-left: auto;
  margin-right: auto; */
}
.radio-btn-container .span {
  display: block;
  /* background: #fee8c3; */
  color: blue;
  font-weight: bold;
  border-radius: 5px;
  padding: 4px 35px;
  border: 2px solid #fdd591;
  margin-bottom: 5px;
  cursor: pointer;
}
.radio-btn-container .span::after,
.radio-btn-container .span::before {
  content: '';
  position: absolute;
  left: 8px;
  top: 6px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background: #fdcb77;
}
.radio-btn-container .span::before {
  background: transparent;
  transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
    0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
  z-index: 1;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: center;
  width: 0;
  height: 0;
  /* background: radial-gradient(
    rgb(32, 49, 178) 0%,
    rgb(32, 49, 178) 40%,
    transparent 50%,
    transparent
  ); */
  border-color: lightseagreen;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+);
}

.radio-btn-container input[type='radio']:checked ~ .span {
  background: #fdcb77;
  -webkit-animation-name: blink;
  animation-name: blink;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  border-color: #fcae2c;
  color: red;
}
.radio-btn-container input[type='radio']:checked + .span::after {
  background: #fcae2c;
}
.radio-btn-container input[type='radio']:checked + .span::before {
  width: 20px;
  height: 20px;
}

@-webkit-keyframes blink {
  0% {
    background-color: #fdcb77;
  }
  10% {
    background-color: #fdcb77;
  }
  11% {
    background-color: #fdd591;
  }
  29% {
    background-color: #fdd591;
  }
  30% {
    background-color: #fdcb77;
  }
  50% {
    background-color: #fdd591;
  }
  45% {
    background-color: #fdcb77;
  }
  50% {
    background-color: #fdd591;
  }
  100% {
    background-color: #fdcb77;
  }
}

@keyframes blink {
  0% {
    background-color: #fdcb77;
  }
  10% {
    background-color: #fdcb77;
  }
  11% {
    background-color: #fdd591;
  }
  29% {
    background-color: #fdd591;
  }
  30% {
    background-color: #fdcb77;
  }
  50% {
    background-color: #fdd591;
  }
  45% {
    background-color: #fdcb77;
  }
  50% {
    background-color: #fdd591;
  }
  100% {
    background-color: #fdcb77;
  }
}
