.title-flex-container {
    display: flex;
    justify-content: flex-start
}

.title {
    margin-top: 0;
}

.link-text {
    margin-top: 5px;
    margin-left: 20px;
    font-size: 1em;
    color: red;
    /* text-decoration: none; */
}