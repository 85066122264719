.flex-password-icon {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.flex-password-icon-item {
  flex: initial;
  margin-right: 20px;
}

.flex-password-icon > span {
  font-size: 1.3em;
  margin-top: 19px;
}
