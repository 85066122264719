.category-tile-container {
  border: solid rgb(209, 208, 208) 1px;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0 4px 19px -9px #222;
  -moz-box-shadow: 0 4px 19px -9px #222;
  box-shadow: 0 4px 19px -7px #222;
}

button {
  background-color: white;
  border: none;
  margin: 2px;
}

.tile-title {
  text-align: center;
  margin-top: 0;
  margin-bottom: 4px;
  /* color: blue; */
}

.tile-info {
  margin-left: 10px;
  color: blue;
}

.tile-text {
  text-align: center;
  color: blue;
  margin-bottom: 4px;
}

.tile-errors {
  text-align: center;
  margin-bottom: 4px;
}

.ellipsis-btn {
  margin-top: 5px;
}

.flex-container {
  display: flex;
  justify-content: space-evenly;
  padding: 0;
  margin: 0;
  flex-flow: row wrap;
  overflow: hidden;
  gap: 25px;
}

.flex-item {
  flex: 0 0 100%;
  font-size: 12px;
  align-self: flex-start;
  min-height: 420px;
  margin-bottom: 10px;
}

@media screen and (min-width: 640px) {
  .flex-item {
    flex: 10 0 45%;
    font-size: 12px;
  }
}

@media screen and (min-width: 900px) {
  .flex-item {
    flex: 10 0 45%;
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .flex-item {
    flex: 10 0 31%;
    font-size: 15px;
  }
}
