.open-sidebar {
  margin-left: 340px;
  transition: 0.5s all ease-in-out;
}

.closed-sidebar {
  margin-left: 0;
  transition: 0.5s all ease-in-out;
}

.device-name {
  margin-left: 10px;
}

.participant-data-flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  flex-flow: row wrap;
  overflow: hidden;
  gap: 20px;
}

.participant-data-flex-item {
  /* margin-top: 5px; */
  margin-left: 10px;
}

.bold-text {
  font-weight: bold;
}
