footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100px;
  padding-top: 10px;
  background-color: white;
  -webkit-box-shadow: 0 8px 19px 4px #222;
  -moz-box-shadow: 0 8px 19px 4px #222;
  box-shadow: 0 8px 19px 4px #222;
}

footer p {
  font-size: 1em;
  margin-left: auto;
  margin-right: auto;
}

.links-container {
  display: flex;
  justify-content: center;
}

.links-container div {
  margin: 10px 10px 0px 10px;
}

@media only screen and (min-width: 640px) {
  footer p {
    font-size: 1.5em;
  }
}
