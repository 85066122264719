.custom-tooltip {
  width: auto;
  background: white;
  color: #333;
  padding: 1px;
  text-align: left;
  border: solid 1px lightgrey;
  padding-bottom: 12px !important;
}

.tooltip-details {
  margin: 13px 19px;
  font-size: 0.7em;
}

/* @media only screen and (min-width: 870px) {
  .tooltip-details {
    font-size: 0.8em;
    font-weight: bold;
  }
} */

@media only screen and (min-width: 960px) {
  .tooltip-details {
    font-size: 0.8em;
    font-weight: bold;
  }
}
