.trials-form .form-item {
  margin: 10px;
}

.trials-form input {
  width: 100%;
}

.trials-form .form-id {
  text-align: center;
}

.trials-form button:hover {
  background-color: lightgray;
}

.icons-flex-container {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  flex-flow: row wrap;
  overflow: hidden;
}

.submit-icon {
  font-size: 3em;
  color: green;
}

.cancel-icon {
  font-size: 3em;
  color: red;
}
