.close-error-msg-btn {
  color: red;
}

.new-trial-btn {
  color: blue;
}

.select_cell {
  cursor: pointer;
}
