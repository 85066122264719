.data-title {
  text-align: center;
}

.charts-flex-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  gap: 10px;
}

.charts-flex-container > .flex-item {
  flex: auto;
  margin-top: 20px;
  padding: 10px;
  overflow-x: auto;
  overflow-y: auto;
  border: solid 1px lightgray;
}

.participant-data-modal {
  width: 90%;
  height: auto;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

@media only screen and (min-width: 1200px) {
  .charts-flex-container {
    flex-wrap: nowrap;
  }
}
