.navbar {
  position: sticky;
  top: 0;
  height: auto;
  width: 100%;
  background-color: white;
  -webkit-box-shadow: 0 4px 19px -6px #222;
  -moz-box-shadow: 0 4px 19px -6px #222;
  box-shadow: 0 4px 19px -6px #222;
  overflow: hidden;
  z-index: 100;
}

.logo a {
  font-family: 'Lora';
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  display: flex;
  text-decoration: none;
}

.logo a p {
  margin-top: 6px;
  padding-left: 3px;
  font-size: 1em;
  font-weight: lighter;
  color: blue;
}

.navbar img {
  width: 32px;
  margin-top: 0px;
}

.navbar:hover img {
  animation: heartbeat-animation 1s infinite;
}

.navbar a:hover {
  cursor: pointer;
  background-color: indianred;
}

.navbar-right-menu {
  font-size: 1.2em;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 50px;
  cursor: pointer;
  color: blue;
}

.fa-bars {
  font-size: 1.3rem;
  width: 100px;
  display: inline-block;
  position: absolute;
  top: 28px;
  right: 10px;
  cursor: pointer;
}

nav ul {
  margin: 0px;
  /* display: flex; */
}

nav li {
  margin-top: 9px;
  padding: 30px 30px;
  color: blue;
  list-style-type: none;
}

nav a {
  font-size: 1em;
  text-decoration: none;
}

nav a .active {
  color: tomato;
}

.profile-icon {
  font-size: 1.2em;
  display: inline-block;
  position: absolute;
  right: 90px;
  top: 26px;
  cursor: pointer;
  color: blue;
}

.profile-icon :hover {
  cursor: pointer;
}

/* .contact-us {
  float: right;
} */

@media only screen and (min-width: 390px) {
  .navbar img {
    width: 40px;
    margin-left: 10px;
  }

  .logo a p {
    font-size: 1.3em;
    margin-top: 4px;
    font-weight: bolder;
  }

  .profile-icon {
    margin-top: 2px;
    font-size: 1.7em;
    right: 100px;
  }

  nav li {
    margin-top: 5px;
  }

  .fa-bars {
    font-size: 2em;
    top: 26px;
  }

  .navbar-right-menu {
    top: 6px;
  }
}

@media only screen and (max-width: 860px) {
  .logo {
    font-size: 1.3em;
    width: 40%;
  }

  nav li {
    padding: 20px 30px;
  }

  ul.collapsed {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: 0;
  }

  ul.is-expanded {
    overflow: hidden;
    max-height: 500px;
  }

  li {
    padding: 10px 0px;
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .logo {
    width: 55%;
  }
}

@media only screen and (min-width: 860px) {
  nav a {
    float: left;
    padding-top: 0px;
    margin-top: 0px;
  }

  .logo a {
    font-size: 1.4em;
  }

  .fa-bars {
    display: none;
  }

  .navbar-right-menu {
    top: 0px;
  }

  .profile-icon {
    top: 26px;
  }
}
