.new-trial-users-btn {
  color: blue;
  margin-left: 20px;
}

.trial-users-flex-container {
  display: flex;
  justify-content: flex-start;
}

.trial-users-flex-item {
  flex: initial;
  margin-right: 0px;
}

#trial-users-close-err-btn {
  font-size: 1.4em;
  margin-top: 15px;
  color: red;
}
