.title-flex-container {
  display: flex;
  justify-content: flex-start;
}

.title-flex-item {
  flex: auto;
  margin-top: 20px;
  padding: 2px;
  /* max-width: 200px; */
}

#manage-users-title_link {
  margin-top: 30px;
  font-size: 1.1em;
}
