.signup-form-select {
  width: 100%;
  outline: none;
  height: 35px;
  margin-bottom: 20px;
  border-radius: 0px;
  border: 0;
  outline: 0;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0);
  border-bottom: solid 2px blue;
}

.flex-sighup-password-icon {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.flex-sighup-password-icon-item {
  flex: initial;
  margin-right: 20px;
}

.flex-sighup-password-icon > span {
  font-size: 1.3em;
  margin-top: 19px;
}
