.devices-info {
  font-size: 0.7em;
}

.devices-info-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.devices-icons-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.devices-basic-info-name {
  font-weight: bold;
}

.battery-icon {
  font-size: 15px;
}

.high-level {
  color: green;
}

.med-level {
  color: rgb(216, 142, 4);
}

.low-level {
  color: orangered;
}

.very-low-level {
  color: red;
}

.empty-level {
  color: red;
}

.device-category-btn {
  background-color: transparent;
  margin: 0px;
  padding: 0px;
}

.device-category-icon {
  font-size: 15px;
}

.blood-pressure-icon {
  font-size: 25px;
}

@media screen and (min-width: 720px) {
  .devices-info {
    font-size: 1em;
  }

  .devices-basic-info-name {
    font-size: 1em;
  }

  .battery-icon {
    font-size: 30px;
  }

  .device-category-btn {
    margin: 5px;
  }

  .device-category-icon {
    font-size: 25px;
  }

  .blood-pressure-icon {
    font-size: 35px;
  }
}
