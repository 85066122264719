.radio-btns-flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: center;
}

/* Hide the original radio button*/
.small-radio-btn-container input[type='radio'] {
  position: absolute;
  opacity: 0;
  margin: 0;
}
.small-radio-btn-container {
  position: relative;
  display: block;
  width: 85px;
}
.small-radio-btn-container .span {
  display: block;
  /* background: #fee8c3; */
  color: blue;
  font-weight: bold;
  border-radius: 5px;
  padding: 4px 5px;
  border: 2px solid #fdd591;
  margin-bottom: 5px;
  cursor: pointer;
}

.small-radio-btn-container input[type='radio']:checked ~ .span {
  background: #fdcb77;
  -webkit-animation-name: blink;
  animation-name: blink;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  border-color: #fcae2c;
  color: red;
}
