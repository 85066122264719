.react-datepicker__close-icon {
  margin-top: 0px;
}

.omron-participant-form {
  width: 90%;
}

.omron-participant-form h4 {
  margin-bottom: 5px;
  color: blue;
}
.calendar .react-datepicker-wrapper {
  margin-bottom: 3px;
}

.calendar .react-datepicker__input-container {
  width: 93%;
}

.calendar .react-datepicker-wrapper input {
  border-radius: 5px;
  padding: 4px 5px;
  border: 2px solid #fdd591;
  /* background: #fee8c3; */
}
