.object {
  color: blue;
}

.listItem {
  margin-left: 30px;
  color: crimson;
}

.statistics-flex-container {
  display: flex;
  /* justify-content: space-evenly; */
  padding: 0;
  margin: 0;
  flex-flow: row wrap;
  overflow: hidden;
}

.statistics-flex-item {
  margin-top: 5px;
  margin-left: 10px;
}

.statistics-info {
  margin-top: 10px;
  color: blue;
}

.trend-icon {
  font-size: 2em;
}

#trend-below-icon {
  color: red;
}

#trend-over-icon {
  color: green;
}

#trend-same-icon {
  font-size: 25px;
  padding-top: 3px;
}
