.profile-menu {
  min-width: 200px;
  width: auto;
  height: auto;
  position: fixed;
  right: 30px;
  top: 60px;
  list-style: none;
  display: block;
  background: white;
  padding: 15px;
  -webkit-box-shadow: 0 4px 19px -6px #222;
  -moz-box-shadow: 0 4px 19px -6px #222;
  box-shadow: 0 4px 19px -6px #222;
}

.profile-menu-item {
  display: block;
  width: 100%;
  padding: 10px;
}
