/* Modal Position */
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal {
  position: fixed;
  width: auto;
  min-width: 90%;
  max-width: 90%;
  height: auto;
  max-height: 90%;
  /* left: 35%;*/
  top: 10%;
  border: solid 2px red;
  z-index: 100;
  background-color: #ffffff;
}

.modal-title {
  text-align: center;
  margin-bottom: 20px;
}

.modal-text {
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
}

/* The modal's close button */
.modal-close {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 25px;
  height: 25px;
  /* line-height: 1.5rem; */
  text-align: center;
  cursor: pointer;
  border: none;
  /* background: #2196f3; */
  /* color: white; */
  /* font-size: 25px; */
}

.modal-close:hover {
  background-color: red;
}

@media screen and (min-width: 640px) {
  .modal {
    /* min-width: 50%;
    max-width: 50%; */
  }
}
