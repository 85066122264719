.sidebar {
  position: fixed;
  border-right: 1px solid black;
  border-radius: 0;
  transition: 0.5s all ease-in-out;
  padding-left: 1.5%;
  left: 0;
  top: 70px;
}

.sidebar .content {
  position: absolute;
  width: 100%;
  height: calc(100vh - 200px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 30px;
}

.toggle-btn {
  position: fixed;
  top: 25px;
  left: 0px;
  height: 50px;
  border-top-right-radius: 10rem;
  border-bottom-right-radius: 10rem;
  width: 15px;
  outline: none;
  z-index: 1;
  background-color: #4a81e6;
  color: white;
  border-left: 0;
  margin-left: 0;
  margin-bottom: 0px;
  font-size: 25px;
}

.toggle-btn .arrow {
  position: relative;
  top: 3px;
  left: -12px;
}
