.button-container {
  display: flex;
  align-items: center; /* Align items vertically in the same row */
  gap: 10px; /* Space between buttons and dropdown */
}

.buttons {
  display: flex;
  gap: 10px; /* Space between individual buttons */
}

.select-dropdown {
  margin-left: auto; /* Push the dropdown all the way to the right */
  height: 150px;
  max-width: 150px; /* Adjust the width if needed */
  box-sizing: border-box; /* Include padding in width */
  line-height: 30px;
}

.btn {
  max-width: 200px;
  text-align: center;
  padding: 10px;
  flex: none; /* Prevent buttons from stretching */
  box-sizing: border-box;
}
