table {
  border-collapse: collapse;
  width: 100%;
  margin: auto;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
  /* min-width: 250px; */
  width: 250px;
  max-width: 400px;
}

table tr {
  overflow-wrap: anywhere;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  /* cursor: pointer; */
  background-color: lightsalmon;
}

.singleRowSelection table tr:hover {
  cursor: pointer;
  background-color: lightsalmon;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: blue;
  background-color: lightseagreen;
}

table th div input {
  height: 30px;
}

table th div svg {
  width: 2em;
  height: 1.5em;
  margin-bottom: -6px;
}

.global-filter-col {
  border: none;
}

.global-filter-col .input-field {
  width: 90%;
}

.table-controllers-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

.table-controllers-item {
  flex: 100%;
}

.show-hide-cols-list-wrapper {
  /* border: solid 1px black; */
}

.show-hide-cols-list-wrapper button {
  font-size: 1em;
}

.show-hide-cols-list {
  display: none;
}

.show-hide-cols-list-active {
  display: block;
  position: absolute;
  padding: 10px;
  background-color: white;
  border: solid 1px lightgray;
  z-index: 1;
}

.sort-arrow {
  height: 1em;
  width: 1em;
  margin-left: 1em;
  background-color: white;
  margin-top: -6px;
}

.pagination {
  text-align: center;
  margin-top: 10px;
  color: blue;
}

.pagination button,
.pagination select,
.pagination input {
  font-size: 0.9em;
  margin-top: 5px;
  color: blue;
  border: solid blue 1px;
  height: 22px;
}

.pagination input {
  width: 80px;
}

.pagination button:disabled {
  color: darkgray;
  border-color: darkgray;
}

.centered-container.max table {
  font-size: 0.6em;
}

.input-container {
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
  margin-left: 2px;
}

.input-container i {
  position: absolute;
}

.input-field {
  width: 80%;
  padding: 5px 5px 5px 30px;
  text-align: left;
}

.search-icon {
  padding: 0px;
  margin-top: 7px;
  margin-left: 3px;
  width: 20px;
  text-align: left;
}

.clear-search-icon {
  font-size: 25px;
  margin-top: 4px;
  cursor: pointer;
}

.link {
  align-content: flex-start;
  font-size: 1em;
  color: black;
}

.link:hover {
  cursor: pointer;
  color: red;
  font-size: 1.1em;
}

.selected-rows-controllers-container {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.selected-rows-controllers-container div {
  margin-right: 20px;
}

.selected-rows-controllers-container .btn {
  min-width: 20px;
  font-size: 0.8em;
}

.export-data.dropdown {
  font-size: 1em;
  margin-left: -2px;
}

.scrollable-cell {
  max-height: 50px; /* Adjust the height as needed */
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: lightseagreen #f1f1f1; /* Firefox */
}

/* For WebKit browsers */
.scrollable-cell::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.scrollable-cell::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 4px; /* Optional: Round the corners of the track */
}

.scrollable-cell::-webkit-scrollbar-thumb {
  background: lightseagreen; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Optional: Round the corners of the thumb */
}

.scrollable-cell::-webkit-scrollbar-thumb:hover {
  background: lightseagreen; /* Color when hovering over the scrollbar thumb */
}

@media only screen and (min-width: 600px) {
  .centered-container.max table {
    font-size: 1em;
  }

  .input-field {
    width: 40%;
  }

  .search-icon {
    width: 1.5em;
    height: 1.5em;
    margin-top: 5px;
  }

  .selected-rows-controllers-container .btn {
    font-size: 1em;
  }

  .table-controllers-item {
    flex: 50%;
  }
}

@media only screen and (max-width: 400px) {
  .centered-container.max table {
    font-size: 0.5em;
  }
}

@media only screen and (max-width: 360px) {
  .centered-container.max table {
    font-size: 0.4em;
  }
}

@media only screen and (max-width: 300px) {
  .centered-container.max table {
    font-size: 0.3em;
  }
}
