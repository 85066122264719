.participant-flex-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  gap: 10px;
}

.participant-flex-container > .flex-item {
  flex: auto;
  height: 250px;
  margin-top: 20px;
  padding: 10px;
  overflow-x: auto;
  overflow-y: auto;
  border: solid 1px black;
}

.participant-flex-container table {
  /* width: auto; */
}

.flex-title {
  display: flex;
  justify-content: flex-start;
}

.flex-title > .trials-flex-item {
  flex: initial;
  margin-right: 20px;
}

@media screen and (min-width: 600px) {
  .participant-flex-container > .flex-item {
    height: 400px;
    flex-basis: min-content;
  }
}
